import React, { useEffect, useMemo, useRef, useState } from "react";
import { useCatalogueService } from "../../../services/useCatalogueService";
import CatalogueGridItem from "../Catalogue/CatalogueGridItem.template";
import { Tab, Tabs } from "@mui/material";
import { useCategoryService } from "../../../services/useCategoryService";
import Button from "../../atoms/Button/Button";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { CATEGORY_LEVEL, CUSTOMER_ROUTES } from "../../../utils/constant";
import { Catalogue } from "../Catalogue/CatalogueList.template";
import Carousel from "react-multi-carousel";
import CarouselRef from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import emptyProducts from "../../../assets/images/emptyCartImage.svg";
import CatalogueGridItemSkeleton from "../Catalogue/CatalogueGridItemSkeleton.template";
import { CATALOGUE_TYPE_STATES, CLASS_STATES, HOMEPAGE_CATALOUGE_SECTION, HOMEPAGE_CATALOUGE_SECTION_HEADINGS } from "../../../utils/types";
import { makeRoute } from "../../../utils/helper";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface IFilterCatalogueListProps {
    title: string;
    status: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: "1440px"
    },
    title: {
        color: theme.palette.v2.text.primaryDark,
    },
    titleBorder: {
        height: "9px",
        width: "111px",
        background: theme.palette.v2.background._primaryLight,
        position: "relative",
        left: "91px",
        top: "25px"
    },
    mobiletitle: {
        color: theme.palette.text.primaryDark,
    },
    titleBorderSecondary: {
        display: 'none',
    },
    catalogueCard: {
        width: "304px",
        minWidth: "180px",
        paddingBottom: "4px",
    },
    viewAllButton: {
        padding: "2px 0px",
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        },
        "& .react-multi-carousel-item": {
        },
        "& .react-multi-carousel-list": {
            width: "1296px",
            margin: "0 auto",
        },
        "& .react-multi-carousel-list li": {
            width: "fit-content !important",
        },
        marginTop: '32px'
    },
    iconTitle: {
        color: theme.palette.text.secondaryDark,
        fontSize: '36px'
    },
    tabMobileContainer: {
        display: "none"
    },
    tabContainer: {
        display: "flex",
        padding: "0px 72px"
    },
    mobileSlider: {
        display: 'none',
    },
    tab: {
        marginRight: "25px"
    },
    mobileContainer: {},
    mobilebtn: {
        display: 'none',
    },
    btn: {
        display: 'block',
    },
    mainSlider: {},
    carouselContainerMobile: {},
    primeContainer: {},
    btnContainer: {},
    "@media (max-width: 340px)": {
        mobiletitle: {
            width: '177px',
        },
    },
    "@media (max-width: 480px)": {
        tabMobileContainer: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '16px',
        },
        btnContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            paddingRight: '16px',
        },
        tabContainer: {
            display: "none"
        },
        carouselContainer: {
            "& .react-multiple-carousel__arrow--right": {
                right: "0 !important"
            },
            "& .react-multiple-carousel__arrow--left": {
                left: "0 !important"
            },
            "& .react-multi-carousel-item": {
                width: "264px !important ",
            },
            marginTop: '16px !important ',
            ' & .react-multi-carousel-list ': {
                overflowX: 'auto ',
            }
        },
        iconTitle: {
            color: theme.palette.text.secondaryDark,
            fontSize: '16px'
        },
        catalogueCard: {
            width: "264px !important",
            minWidth: "100px",
            paddingBottom: "4px",
        },
        mobileContainer: {
            marginLeft: '0px !important',
            width: '320px !important',
        },
        customArrow: {
            display: 'none',
        },
        mobilebtn: {
            display: 'block',
            padding: '0px',
            fontSize: '14px',
            color: theme.palette.v4.text.secondaryLightAccent,
        },
        btn: {
            display: 'none',
        },
        carouselContainerMobile: {
            width: 'max-content',
            overflow: 'auto',
            "& .react-multi-carousel-list  ": {
                width: '100vw',
                paddingLeft: '16px',
            }
        },
        primeContainer: {
            "& .react-multi-carousel-list  ": {
                width: '100vw',
                padding: '0px 16px',
                "& .react-multi-carousel-track": {
                    width: '100%',
                },
            }
        },
        container: {
            width: '100% !important'
        },
        mobiletitle: {
            color: theme.palette.v3.text._secondaryLightAccent,
            position: 'relative',
            fontSize: '18px',
            zIndex: '99',
        },
        titleBorderSecondary: {
            display: 'block',
            height: "11px",
            width: "92px",
            background: theme.palette.v2.background._primaryLight,
            position: "relative",
            // background: 'red',
            left: "66px",
            top: "23px",
            zIndex: '1',
        },
        mainSlider: {
            display: 'none',
        },
        mobileSlider: {
            display: 'block',
        },
        tab: {
            marginRight: "16px"
        },

    },
    customArrow: {
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        cursor: "pointer",
        transition: "box-shadow 0.3s ease-in-out",
        boxShadow: `0px 0px 6px 0px ${theme.palette.boxShadow.secondaryDark}`,
        "&:hover": {
            boxShadow: `0px 0px 6px 0px ${theme.palette.boxShadow.primaryDark}`
        }
    },
    viewAll: {
        color: theme.palette.v2.text._primaryDarkAccent,
    },
    customButtonContainer: {
    },
}));

interface ICategoryItem {
    id: number | string;
    label: string;
}

interface Payload {
    categoryId?: string | number;
    catalogueTypes?: string[];
    classes?: string[];
}

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 4,
        partialVisibilityGutter: 0
    },
    tablet: {
        breakpoint: { max: 1280, min: 980 },
        items: 3,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 980, min: 670 },
        items: 2,
        partialVisibilityGutter: 10
    },
    "sm-mobile": {
        breakpoint: { max: 480, min: 0 },
        items: 2,
        partialVisibilityGutter: 0
    }
};

const FilterCatalogueList: React.FC<IFilterCatalogueListProps> = ({ title, status }) => {
    const classes = useStyles();
    const catalogueService = useCatalogueService();
    const [catalogues, setCataloguesTo] = useState<Catalogue[]>([]);
    const navigate = useNavigate();
    const catagoryService = useCategoryService();
    const [categories, setCategoriesTo] = useState<ICategoryItem[]>([]);
    const [activeCategoryTabIndex, setActiveCategoryTabIndex] = useState<number>(0);
    const carouselRef = useRef<CarouselRef>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.goToSlide(0, true);
        }
    }, [activeCategoryTabIndex, categories]);

    const handleTabChange = (
        event: React.SyntheticEvent,
        currentTabIndex: any
    ) => {
        setActiveCategoryTabIndex(currentTabIndex);
    };

    const loadCategories = async () => {
        try {
            const params = {
                level: CATEGORY_LEVEL.PRODUCT_CATEGORY,
                limit: 7,
            };
            const categoriesResponse = await catagoryService.getAllCategories(params);
            if (categoriesResponse?.data?.data?.length) {
                setCategoriesTo(categoriesResponse.data.data);
                setActiveCategoryTabIndex(0);
            } else {
                throw new Error("Categories fetch failed");
            }
        } catch (error) {
            setCategoriesTo([]);
        }
    };


    const fetchCatalogues = async () => {
        if (!categories?.[activeCategoryTabIndex]?.id) return null;
        try {
            setLoading(true);

            const payload: Payload = {};

            switch (status) {
                case HOMEPAGE_CATALOUGE_SECTION.PROCESS_PRIME: {
                    payload.classes = [CLASS_STATES.SECONDARY];
                    payload.catalogueTypes = [CATALOGUE_TYPE_STATES.CURRENT_INVENTORY];
                    break;
                }
                case HOMEPAGE_CATALOUGE_SECTION.CHOOSE_YOUR_SPECIFICATIONS: {
                    payload.classes = [CLASS_STATES.STANDARD, CLASS_STATES.NONSTANDARD];
                    payload.catalogueTypes = [CATALOGUE_TYPE_STATES.MAKE_TO_ORDER];
                    break;
                }
                default: {
                    payload.classes = [CLASS_STATES.SECONDARY];
                    payload.catalogueTypes = [CATALOGUE_TYPE_STATES.MAKE_TO_ORDER];
                }
            }
            const cataloguesResponse = await catalogueService.getAllCatalogues(
                {
                    page: 0,
                    size: 10,
                    sort: "createdAt,desc",
                },
                payload
            );
            if (cataloguesResponse?.data?.data?.content?.length) {
                const { content } = cataloguesResponse.data.data;
                setCataloguesTo(content);
                setLoading(false);
            } else {
                throw new Error("Catalogue fetch failed");
            }
        } catch (error) {
            setCataloguesTo([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadCategories();
    }, []);

    useEffect(() => {
        fetchCatalogues();
    }, [activeCategoryTabIndex, categories]);

    const handleClick = () => {
        switch (status) {
            case HOMEPAGE_CATALOUGE_SECTION.CHOOSE_YOUR_SPECIFICATIONS: {
                navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { "catalogueTypes": CATALOGUE_TYPE_STATES.MAKE_TO_ORDER, "categoryId": categories?.[activeCategoryTabIndex]?.id } }));
                break;
            }
            case HOMEPAGE_CATALOUGE_SECTION.PROCESS_PRIME: {
                navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { "classes": CLASS_STATES.SECONDARY, "categoryId": categories?.[activeCategoryTabIndex]?.id } }));
                break;
            }
            default: {
                navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { "classes": CLASS_STATES.SECONDARY, "catalogueTypes": CATALOGUE_TYPE_STATES.MAKE_TO_ORDER, "categoryId": categories?.[activeCategoryTabIndex]?.id } }));
            }
        }
    };

    const hotOffer = (title === HOMEPAGE_CATALOUGE_SECTION_HEADINGS.TODAYS_HOT_OFFERS);

    const ButtonGroup = ({ next, previous, carouselState, style }: any) => {
        const { currentSlide, totalItems, slidesToShow } = carouselState;
        const isFirstSlide = currentSlide === 0;
        const isLastSlide = currentSlide === totalItems - slidesToShow;
        const isOverflowing = totalItems > slidesToShow;

        return (
            <div className={`${classes.customButtonContainer} flex justify-between items-center w-full px-3`} style={{ marginTop: `${style.marginTop}` }}>
                {!isFirstSlide && (
                    <button className={`${classes.customArrow} bg-white flex items-center`} onClick={previous}>
                        <KeyboardArrowLeftIcon className="mx-auto" sx={{ fontSize: 30 }} />
                    </button>
                )}
                {isOverflowing && !isLastSlide && (
                    <button className={`${classes.customArrow} bg-white flex items-center ml-auto`} onClick={next}>
                        <KeyboardArrowRightIcon className="mx-auto" sx={{ fontSize: 30 }} />
                    </button>
                )}
            </div>
        );
    };

    const catalogueDisplay = useMemo(() => {
        if (loading) {
            return (
                <div className={`${classes.carouselContainer} flex gap-x-8`}>
                    {[null, null, null, null].map((item: any, index: any) => (
                        <div className={classes.catalogueCard} key={index}>
                            <CatalogueGridItemSkeleton />
                        </div>
                    ))}
                </div>
            )
        } else if ((catalogues.length <= 4 || title !== HOMEPAGE_CATALOUGE_SECTION_HEADINGS.CHOOSE_YOUR_SPECIFICATIONS) && catalogues.length !== 0) {
            return (
                <div className={`${classes.carouselContainer}  ${classes.primeContainer} relative`} style={{ height: "392px" }}>
                    <Carousel
                        responsive={responsive}
                        showDots={false}
                        infinite={false}
                        partialVisible={true}
                        ref={carouselRef}
                        arrows={false}
                        removeArrowOnDeviceType={["sm-mobile"]}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup style={{ marginTop: "-15%" }} />}
                    >
                        {catalogues.map((item: any, index: any) => (
                            <div className={` ${classes.catalogueCard} ${index !== catalogues.length - 1 ? classes.tab : ''}`} key={item.id} >
                                <CatalogueGridItem data={item} />
                            </div>
                        ))}
                    </Carousel>
                </div>
            )
        } else if (catalogues.length > 4) {
            return (
                <>
                    <div className={`${classes.carouselContainer} ${classes.mainSlider} relative`} style={{ height: "811px" }}>
                        <Carousel
                            responsive={responsive}
                            showDots={false}
                            infinite={false}
                            partialVisible={true}
                            ref={carouselRef}
                            arrows={false}
                            className={classes.mobileContainer}
                            removeArrowOnDeviceType={["sm-mobile"]}
                            renderButtonGroupOutside={true}
                            customButtonGroup={<ButtonGroup style={{ marginTop: "-30%" }} />}
                        >
                            {(() => {
                                const carouselItems = [];
                                const lastIndex = catalogues.length - 1;
                                for (let index = 0; index <= lastIndex; index += 2) {
                                    const item1 = catalogues[index];
                                    const item2 = index + 1 <= lastIndex ? catalogues[index + 1] : null;
                                    carouselItems.push(
                                        <div className={`${classes.catalogueCard} ${index !== lastIndex ? classes.tab : ''} flex flex-col gap-y-8`} key={index}>
                                            <CatalogueGridItem data={item1} />
                                            {item2 && <CatalogueGridItem data={item2} />}
                                        </div>
                                    );
                                }
                                return carouselItems;
                            })()}
                        </Carousel>
                    </div>

                    <div className={`${classes.carouselContainer} ${classes.primeContainer} ${classes.mobileSlider}  relative`} style={{ height: "392px" }}>
                        <Carousel
                            responsive={responsive}
                            showDots={false}
                            infinite={false}
                            partialVisible={true}
                            ref={carouselRef}
                            arrows={false}
                            removeArrowOnDeviceType={["sm-mobile"]}
                            renderButtonGroupOutside={true}
                            customButtonGroup={<ButtonGroup style={{ marginTop: "-15%" }} />}
                        >
                            {catalogues.map((item: any, index: any) => (
                                <div className={` ${classes.catalogueCard} ${index !== catalogues.length - 1 ? classes.tab : ''}`} key={item.id} >
                                    <CatalogueGridItem data={item} />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </>



            )
        } else {
            return (
                <div className="w-full flex justify-center items-center">
                    <div className="grid  items-center m-auto ">
                        <img className="m-auto w-3/4" src={emptyProducts} alt="pic" />
                        <div className={` ${classes.iconTitle} font-black m-auto`}>
                            We're adding more products soon!
                        </div>
                    </div>
                </div>
            )
        }
    }, [loading, catalogues]);

    return (
        <>

            {
                catalogues.length > 0 && <div className={`${classes.container} m-auto max-container`}>
                    <div className={` justify-between ${classes.tabContainer}`}>
                        <div className="relative">
                            {hotOffer && <div className={classes.titleBorder} ></div>}
                            <div className={`${classes.title} flex text-2xl z-10 relative font-semibold items-center gap-1.6`}>{title}</div>
                        </div>
                        <div className="flex gap-x-3">
                            {!hotOffer && <div className={classes.viewAllButton}>
                                <Button variant="text" label="View All Categories" onClick={handleClick} className={`${classes.viewAll} !px-0 !text-lg !font-medium`} />
                            </div>}
                            {hotOffer && <div className={classes.viewAllButton}>
                                <Button variant="text" label="View All " onClick={handleClick} className={`${classes.viewAll} !px-0 !text-lg !font-medium`} />
                            </div>}
                        </div>
                    </div>

                    <div className={`${classes.tabMobileContainer}`}>
                        <div className={`${classes.btnContainer} flex justify-between`}>
                        <div className="relative">
                            {hotOffer && <div className={classes.titleBorderSecondary} ></div>}
                            <div className={`${classes.mobiletitle} flex text-base font-bold  items-center gap-1.6`}>{title}</div>
                            </div>
                            {/* <div className={`${classes.titleBorderSecondary}`}></div> */}
                            <div className={classes.viewAllButton}>
                                <Button variant="text" label="View All Categories" onClick={handleClick} className={`${classes.btn} !py-0 !font-medium`} />
                                <Button variant="text" label="View All" onClick={handleClick} className={`${classes.mobilebtn} !py-0 !font-medium`} />
                            </div>
                        </div>
                        {/* <Tabs
                    value={activeCategoryTabIndex}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons={false}
                    allowScrollButtonsMobile
                    className="flex overflow-x-auto sm:overflow-x-visible"
                >
                    {categories.map((category, index) => (
                        <Tab key={category.id} label={category.label} value={index} />
                    ))}
                </Tabs> */}
                    </div>
                    {
                        catalogueDisplay
                    }
                </div>
            }
        </>

    );
};

export default FilterCatalogueList;
